<template>
  <div>
    <el-button v-for="btn in btns" :key="btn.idx" @click="clickOperate(btn.idx)">{{ btn.name }}</el-button>

    <iframe v-if="siteBUrl" :src="siteBUrl" width="100%" height="808px" frameborder="0"></iframe>
  </div>


</template>

<script>
const config = require("@/configs/config.json")


export default {
  data() {
    return {
      btns: [],
      siteBUrl: ""
    }
  },

  mounted() {

    this.btns = config.bars[this.idx - 1].btns

  },

  props:["idx"],

  methods: {
    clickOperate(index) {
      if (this.btns[index - 1].iframe) {
        this.siteBUrl = this.btns[index - 1].href;

      }
      else {
        window.open(this.btns[index - 1].href, '_blank');
      }
    }
  }
};
</script>

<style scoped>
h1 {
  color: #409EFF;
}
</style>