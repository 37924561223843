<template>
  <h1>BS工作台 v1.0</h1>
  <el-button size="small" type="primary" style="margin-left:15px" @click="downloadConfig">下载配置模板</el-button>
  <el-upload class="upload-demo" :action=uploadUrl
    :auto-upload="true" multiple=false accept=".json" :show-file-list="false" :on-success="uploadSuccess"
    :on-error="uploadError" :limit="1">
    <el-button size="small" type="success" style="margin-left:15px">上传配置编译</el-button>
  </el-upload>


    <button id="btnAddToDeskTop" size="small" type="primary" style="margin-left:15px;margin-top:15px">添加到桌面Go</button>

  <!-- <script>
  export default {
    data() {
      return {
        fileList: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}]
      };
    },
    methods: {
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview(file) {
        console.log(file);
      },
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${ file.name }？`);
      }
    }
  }
</script> -->

  <!-- <el-button size="mini" icon="el-icon-download" type="warning" style="margin-left:15px" @click="downloadConfig">下载配置模板</el-button> -->
  <el-container style="height: 100vh;">
    <el-aside width="200px">
      <SideBar @module-select="selectModule" />
    </el-aside>
    <el-container>
      <el-header>
        <h1>{{ title }}</h1>
      </el-header>
      <el-main>
        <!-- <main-content :module="activeModule" /> -->
        <DevContent :idx="1" v-show="currShowIdx == '1'"></DevContent>
        <DevContent :idx="2" v-show="currShowIdx == '2'"></DevContent>
        <DevContent :idx="3" v-show="currShowIdx == '3'"></DevContent>
        <DevContent :idx="4" v-show="currShowIdx == '4'"></DevContent>
        <DevContent :idx="5" v-show="currShowIdx == '5'"></DevContent>

      </el-main>
    </el-container>
  </el-container>
</template>

<script>







import SideBar from './components/SideBar.vue';
import DevContent from './components/DevContent.vue';
// import OperateContent from './components/OperateContent.vue';
// import DocumentContent from './components/DocumentContent.vue';
const config = require("@/configs/config.json")

export default {
  components: {
    SideBar,
    DevContent,
    // OperateContent,
    // DocumentContent
  },
  data() {
    return {
      currShowIdx: 1,
      title: "",

      //这里必须不能使用CF代理的https或http链接 所以写死
      uploadUrl:"http://work.isesame.xyz" + ":7777/upload_workspace_config?prjName=gamegamegame"
    };
  },
  methods: {
    selectModule(index) {
      this.currShowIdx = index;
      this.title = config.bars[index - 1].title;
    },
    downloadConfig() {
      const link = document.createElement('a');
      link.href = "/config.json";
      link.setAttribute('download', 'config.json');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },


    uploadError(err) {
      console.log(err, "!!!");

      if (window.location.protocol == "https:") {
        this.$message({ message: '请使用http前缀访问后再使用此功能', type: 'error' });
      }
      else {
        this.$message({ message: '上传失败，请通知管理员', type: 'error' });
      }
    },

    addToDeskTop() {

     
    },

    uploadSuccess(res) {
      console.log(res, "???");


      this.$message({ message: '配置上传成功，正在编译', type: 'success' });
      window.location.href = "http://work.isesame.xyz" +  ":7777/buildTestLog?prj=gamegamegame"
      // if (res.code == 0) {
      // this.$message({ message: '配置上传成功，正在编译', type: 'success' });
      // } else {
      //   this.$message({ message: res.msg, type: 'error' });
      // }
    },
  },

  mounted() {
    this.title = config.bars[this.currShowIdx - 1].title;
   
    
  }
};
</script>

<style>
.el-header {
  background-color: #f5f5f5;
  padding: 10px;
}
</style>
