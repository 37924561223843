<template>
    <el-menu @select="handleSelect" >

      <el-menu-item v-for ="sideBar in sideBars" :key="sideBar.title" :sideBar="sideBar" :index="sideBar.index">{{ sideBar.title }}</el-menu-item>
 
    </el-menu>
  </template>
  
  <script>
    const config = require("@/configs/config.json") 

  export default {
    data(){
        return{
            sideBars:config.bars
        }
    },
    methods: {
      handleSelect(index) {
        this.$emit('module-select', index);
      },
    },
  };
  </script>
  
  <style scoped>
  .el-menu {
    width: 200px;
  }
  </style>
  